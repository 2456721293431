/**
 *
 * @param {Array} payments
 * @param {String} amountProp
 * @param {String} decimalProp
 */

// eslint-disable-next-line no-unused-vars
function calculateTotalDecimalValue(payments, amountProp, decimalProp) {
  if (Array.isArray(payments)) {
    return payments.reduce(
      function (total, payment) {
        return total.plus(
          (
            payment[amountProp]
            && payment[decimalProp] !== undefined
            && toFixed(
              payment[amountProp],
              payment[decimalProp]
            )
          ) || 0);
      },
      new Decimal(0)
    );
  }
  return;
}

/**
 *
 * @param {Backbone.Collection} payments
 * @param {String} prop
 */
// eslint-disable-next-line no-unused-vars
function calculateTotalWithGet(payments, prop) {
  return payments.reduce(
    function (total, payment) {
      return total.plus(
        (payment.get && payment.get(prop)) || 0
      );
    },
    new Decimal(0)
  );
}

/**
 * Build the proper object with the info that must be sent to Verify Payment component.
 *
 * @param {VERIFY_PAYMENT_ENTITY_TYPES} entityType Payment entity type
 * @param {Object} sourceInfo Payment/s inform that will be used
 */
function buildEntityInfoForVerifyPaymentComponent(entityType, sourceInfo) {
  var entity;
  var res;
  if (entityType === VERIFY_PAYMENT_ENTITY_TYPES.PAYMENT) {
    entity = buildEntityForPaymentCase(sourceInfo);
  } else if (entityType === VERIFY_PAYMENT_ENTITY_TYPES.MANYPAYMENTS) {
    entity = buildEntityForManyPaymentsCase(sourceInfo);
  } else if (entityType === VERIFY_PAYMENT_ENTITY_TYPES.MULTIPAYMENTS) {
    entity = buildEntityForMultipaymentsCase(sourceInfo);
  }
  res = {
    entity: entity
  };
  if (entityType && sourceInfo) {
    res.format_amount = true;
  }
  return res;
}

/**
 * Build an object with the info that must be sent to Verify Payment component, with the format:
 * {
 *   entity: {
 *     payment: {
 *       money: {
 *         amount,
 *         currency
 *       },
 *       beneficiary_id,
 *       destination_account_number
 *     }
 *   }
 * }
 */
function buildEntityForPaymentCase(sourcePayment) {
  var payment = serializePayment(sourcePayment);
  return {
    payment: payment
  };
}

/**
 * Build an object with the info that must be sent to Verify Payment component, with the format:
 * {
 *   entity: {
 *     manypayments: {
 *       payments: [{
 *         money: {
 *           amount,
 *           currency
 *         },
 *         beneficiary_id,
 *         destination_account_number
 *       }],
 *       total: {
 *         amount,
 *         currency
 *       }
 *     }
 *   }
 * }
 */
function buildEntityForManyPaymentsCase(sourcePayments) {
  var payments = sourcePayments.map(function (payment) {
    return serializePayment(payment);
  });
  var total = serializeTotal(sourcePayments);
  return {
    manypayments: {
      payments: payments,
      total: total
    }
  };
}

/**
 * Build an object with the info that must be sent to Verify Payment component, with the format:
 * {
 *   entity: {
 *     multipayments: {
 *       payments: [{
 *         money: {
 *           amount,
 *           currency
 *         },
 *         beneficiary_id,
 *         destination_account_number
 *       }]
 *     }
 *   }
 * }
 */
function buildEntityForMultipaymentsCase(sourcePayments) {
  var payments = sourcePayments.map(function (payment) {
    return serializePayment(payment);
  });
  return {
    multipayments: {
      payments: payments
    }
  };
}

function serializePayment(payment) {
  var paymentInfo;
  if (payment) {
    paymentInfo = {
      money: {
        amount: format_number_decimals(payment.amount, payment.decimals, useLocale = false),
        currency: payment.currency
      },
      beneficiary_id: payment.beneficiaryId,
      destination_account_number: payment.bankAccountIban || payment.bankAccountAccountNumber
    }
  }
  return paymentInfo;
}

/**
 * Return the payments total info with the format: { amount: string, currency: string }.
 *
 * @param {Array} payments List of payments
 * @return {Object} Payments total info
 */
function serializeTotal(payments) {
  var total = {};
  if (payments && payments.length) {
    var totalAmount = calculateTotalDecimalValue(payments, 'amount', 'decimals');
    total.amount = format_number_decimals(totalAmount, payments[0].decimals, useLocale = false);
    total.currency = payments[0].currency;
  }
  return total;
}

/**
 * Returns a collection of beneficiary identifiers that has been marked as `to be trusted`.
 * No duplicated identifiers are included.
 *
 * @param {Object} payments List of payments
 * @param {String} attrToFilterBy If this parameter is included, it will be used as additional filter
 * @return {Array} List of beneficiary identifiers marked as trusted
 */
function buildTrustedBeneficiariesInfoForVerifyPaymentComponent(payments, attrToFilterBy) {
  var beneficiariesMarkedAsTrusted;
  var distinctTrustedBeneficiariesIds;

  if (payments && payments.length) {
    distinctTrustedBeneficiariesIds = new Set(
      payments
        .filter(function (payment) {
          if (attrToFilterBy) {
            return payment[attrToFilterBy];
          } else {
            return true;
          }
        })
        .map(function (payment) {
          return payment.beneficiary.id;
        })
    );
    beneficiariesMarkedAsTrusted = Array.from(distinctTrustedBeneficiariesIds);
  }
  return beneficiariesMarkedAsTrusted;
}

/**
 * From a list of beneficiaries, returns a set with the identifiers of those who are trustable.
 * 
 * @param {Array} beneficiaries List of beneficiaries
 * @return {Set} Set of trustable beneficiary identifiers
 */
function getDistinctTrustableBeneficiaryIds (beneficiaries) {
  var trustableBeneficiaryIds;
  if (beneficiaries && beneficiaries.length) {
    trustableBeneficiaryIds = new Set(beneficiaries
      .filter(function (item) {
        return item['trust_status'] === TRUST_BENEFICIARY_STATUS.TRUSTABLE;
      })
      .map(function (item) {
        return item['beneficiary_id'];
      })
    );
  }
  return trustableBeneficiaryIds;
}

/**
 * Returns the amount to be used for Fee calculations. It converts
 * amounts to buy currency, applying the given rate,
 * in the case of sell operations.
 * @param {Decimal} amount
 * @param {Decimal} rate
 * @param {String} operation
 */
// eslint-disable-next-line no-unused-vars
function convertPaymentAmountForFeeCalculation(amount, rate, operation) {
  var converted = amount instanceof Decimal ? amount : new Decimal(amount);
  var rateToApply = rate instanceof Decimal ? rate : new Decimal(rate);
  if (operation === 'sell') {
    converted = converted.times(rateToApply);
  }
  return converted;
}
