/* global Vue, getCookie, setCookie */

(function (Vue, getCookie, setCookie) {
  new Vue({
    el: '#js-privacy-policy',
    template: '#js-privacy-policy-template',

    data: function () {
      return {
        cookie: Boolean(getCookie('privacy_policy'))
      };
    },

    methods: {
      /**
       * Set cookie privacy_policy
       */
      acceptPrivacyPolicy: function () {
        setCookie('privacy_policy', 1);
        this.cookie = Boolean(getCookie('privacy_policy'));
      }
    }
  });
}(Vue, getCookie, setCookie));
