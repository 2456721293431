/**
 * Constants for all types of filters
 */
var FILTERTYPES = {
  ALL: 'all',
  MULTI_PAYMENTS: 'multi-payments',
  SINGLE_PAYMENTS: 'single-payments',
  PENDING_AUTHORISATION: 'pending-authorisation',
  PENDING_YOUR_AUTHORISATION: 'pending-your-authorisation',
  WAITING_FOR_TRADE_FUNDS: 'waiting-for-trade-funds',
  CUSTOM_FILTER: 'custom-filter',
  CURRENT_MONTH: 'currentMonth',
  PREVIOUS_MONTH: 'previousMonth',
  LAST_30_DAYS: 'last30Days',
};

var INCOMING_FUNDS_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

/**
 * var for a types of user workflow
 */
var USERS_TYPES_WORKFLOW = {
  SIMPLE: 0,
  EYES_4: 1,
  EYES_6: 2,
  EYES_8: 3,
  EYES_10: 4,
};

var OPERATIONS = {
  BUY: 'buy',
  SELL: 'sell'
};

var DEFAULT_COUNTRY_CODE = 'gb';

var TRUST_BENEFICIARY_STATUS = {
  TRUSTED: 'trusted',
  TRUSTABLE: 'trustable',
  UNTRUSTED: 'untrusted'
};

var DEFAULT_CURRENCY_DECIMALS = 2;

var NUMBER_OF_PHONE_NUMBER_DIGITS_WITHOUT_OBFUSCATION = 2;

var OPERATIONS_EMAIL = 'operationsteam@ebury.com';

var VERIFY_PHONE_REASON = {
  SCA_PAYMENTS: 'user_setup_payments'
};

var DEFAULT_DIRECT_PAYMENT_THRESHOLD_CCY = 'GBP';

var REQUEST_NEW_CODE_COUNTDOWN_VALUE = 60;

var VERIFY_PAYMENT_ENTITY_TYPES = {
  PAYMENT: 'payment',
  MANYPAYMENTS: 'manypayments',
  MULTIPAYMENTS: 'multipayments'
};

var MULTIPAYMENT_VALUE_DATE_MEANING = {
  EXECUTION_DATE: 'execution_date',
  DELIVERY_DATE: 'delivery_date',
  VALUE_DATE: 'value_date',
};

var API_RESPONSE_CODE_ERROR_MULTIRATE = '1137';
