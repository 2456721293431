/* global Vue, messages */

(function (Vue, messagesFromServer) {
  window.notifications = {
    /**
     * Adds a generic error message to handle unknown errors.
     */
    addGenericErrorNotification: function () {
      Vue.prototype.$bus.$emit('notification', {
        message: 'Something went wrong, please try again or contact support',
        type: 'error'
      });
    },

    /**
     * Displays a notification by emitting a notification event to the shared bus
     * @param message {String} Message of the notification
     * @param additionalText {String} Additional text of the notification
     * @param type {String} Type of the notification
     */
    displayNotification: function (message, additionalText, type) {
      Vue.prototype.$bus.$emit('notification', {
        message: message,
        additionalText: additionalText,
        type: type
      });
    },

    /**
     * Store in sessionStorage a new message.
     *
     * @param newMessage {String} Message to save
     * @param type {String} Message's type (success|info|warning|error)
     */
    storeMessageInSessionStorage: function (newMessage, type) {
      var messages = JSON.parse(sessionStorage.getItem('messages') || '[]');

      // Store `newMessage` in sessionStorage only if it is not included yet
      messages.find(function (message) {
        return message.content === newMessage && message.type === type;
      }) || messages.push({
        content: newMessage,
        type: type
      });
      sessionStorage.setItem('messages', JSON.stringify(messages));
    }
  };

  new Vue({
    el: '.js-notifications',

    methods: {
      /**
       * Display as notifications all the messages contained in sessionStorage.
       * Then, remove the messages from sessionStorage.
       */
      displayPendingMessagesFromSessionStorage: function () {
        // Display every pending message
        var messages = JSON.parse(sessionStorage.getItem('messages') || '[]');
        messages.forEach(function (message) {
          window.notifications.displayNotification(message.content, null, message.type);
        });

        // Remove all messages, since they have been displayed
        sessionStorage.removeItem('messages');
      }
    },

    mounted: function () {
      // Some messages can arrive from Django Message API when the page is loaded. Include them in sessionStorage
      messagesFromServer.forEach(function (msg) {
        window.notifications.storeMessageInSessionStorage(msg.content, msg.type);
      });

      this.displayPendingMessagesFromSessionStorage();
    }
  });
})(Vue, messages);
