/* eslint no-unused-vars: "off" */

String.format = function () {
  var s = arguments[0];
  for (var i = 0; i < arguments.length - 1; i++) {
    var reg = new RegExp('\\{' + i + '\\}', 'gm');
    s = s.replace(reg, arguments[i + 1]);
  }
  return s;
};

Object.size = function (obj) {
  var size = 0, key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

if (!Object.keys) {
  Object.keys = function(obj) {
    var keys = [];

    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        keys.push(i);
      }
    }

    return keys;
  };
}

function format_date(date) {
  if (!date) return date;
  var dateStr = date.toString();
  if (dateStr.length === 8) {
    var datepickerLocale = currentLocale.replace(/\-[a-z]+$/g, function(match) { return match.toUpperCase(); });
    // We don't include a datepicker locale for english (as it is the default locale) so we have to change the
    // key to be empty (default locale)
    if (datepickerLocale === 'en' || datepickerLocale === 'en-US') {
      datepickerLocale = '';
    }
    var date_dict = {
      // django date format syntax
      // https://docs.djangoproject.com/en/dev/ref/templates/builtins/#date
      'Y': dateStr.substr(0, 4),
      'y': dateStr.substr(2, 4),
      'n': parseInt(dateStr.substr(4, 2)),
      'j': parseInt(dateStr.substr(6, 2)),
      'm': dateStr.substr(4, 2),
      'd': dateStr.substr(6, 2),
      'b': jQuery.datepicker.regional[datepickerLocale].monthNamesShort[parseInt(dateStr.substr(4, 2)) - 1]
    };

    var result = LOCALE.SHORT_DATE_FORMAT;

    for (var key in date_dict) {
      if (date_dict.hasOwnProperty(key)) {
        result = result.replace(key, date_dict[key]);
      }
    }
  } else {
    result = dateStr;
  }

  return result;
}

function get_format_date_syntaxt(type) {
  var date_dict = {
    // django date format syntax
    // https://docs.djangoproject.com/en/dev/ref/templates/builtins/#date
    'm': 'mm',
    'd': 'dd',
    'Y': 'yy',
    'y': 'y',
    'n': 'm',
    'j': 'd',
    'b': 'M',
    'M': 'M',
    'F': 'MM'
  };

  var result = LOCALE.SHORT_DATE_FORMAT;

  for (var key in date_dict) {
    if (date_dict.hasOwnProperty(key)) {
      result = result.replace(key, date_dict[key]);
    }
  }
  return result;
}

/**
 * Format a number with the given decimal precision.
 *
 * If the decimal precision is unset or is set to zero the number is truncated
 * to integer part else the number is rounded to given precision.
 *
 *
 * @param number {Number|Decimal} Any javascript valid number
 * @param precision {Number} Decimal precision to be showed
 * @param useLocale {boolean} Whether to format the return value according to the user's locale
 * @returns {string} A rounded or truncated number casted to string, optionally locale-formatted
 */
function format_number_decimals(number, precision, useLocale) {
  if (useLocale === undefined) {
    useLocale = true;
  }
  fixed = toFixed(number, precision);
  if (useLocale) {
    return fixed.toLocaleString(currentLocale, {minimumFractionDigits: precision});
  }
  return fixed.toString();
}

/**
 * Format input using Cleave
 * @param input jQuery object pointing to input field
 * @param decimals Number of decimals to use
 * @param options Optional arguments, if not passed take the defaults
 * @return jQuery object pointing to input field
 */
function format_input_number(input, decimals, options) {
  var defaults = {
    numeralPositiveOnly: false
  };
  var settings = $.extend({}, defaults, options);
  var cleave = input.data('cleave');
  if (cleave) {
    cleave.destroy();
  }
  cleave = new Cleave(input.get(), {
    numeral: true,
    numeralDecimalScale: decimals,
    numeralDecimalMark: LOCALE.DECIMAL_SEP,
    delimiter: LOCALE.THOUSAND_SEP,
    numeralPositiveOnly: settings.numeralPositiveOnly
  });
  input.data('cleave', cleave);
  return input;
}

/**
 * Fixes number of decimals of a number rounding the remaining decimal expansion
 *
 * @example
 * toFixed(1.2000048000192, 6) --> 1.200005
 *
 * @example
 * toFixed(1.2060301507537687, 6) --> 1.20603
 *
 * @example
 * toFixed(33248.389123, 2) --> 33248.39
 *
 * @param {number|string|Decimal} number Number to be fixed
 * @param {number} precision Number of digits after the decimal point
 * @param {number} Decimal rounding mode used
 * @returns {number} Number fixed
 */
function toFixed(number, precision, roundingMode) {
  if (!(number instanceof Decimal)) {
    number = number || 0;
    number = new Decimal(number);
  }

  if (typeof roundingMode !== 'number') {
    roundingMode = Decimal.ROUND_HALF_UP;
  }

  if (typeof precision === 'number') {
    number = number.toDecimalPlaces(precision, roundingMode);
  }

  return number.toNumber();
}

/**
 * Returns an instance of Decimal, for falsy values returns a Decimal instance with zero value
 *
 * @param value {Number} Numeric value
 * @returns {Decimal} Decimal instance
 */
function newDecimal(value) {
  var isValidNumber = /\d/.test(String(value));

  if (value === null || typeof value === 'undefined' || !isValidNumber) {
    value = 0;
  }

  return new Decimal(value);
}

$('input, textarea').placeholder();

$('.menu-disabled').on('click', function (event) {
  event.preventDefault();
});

$('#menu-trade[data-toggle="tooltip"]').tooltip();

_.templateSettings = {
  evaluate: /<\[([\s\S]+?)\]>/g,
  interpolate: /<\[=([\s\S]+?)\]>/g,
  escape:/<\[-([\s\S]+?)\]>/g
};

// Gets the value of the csrftoken
var csrftoken = getCookie(csrfTokenCookieName);

/**
 * Checks if in the given method is needed a csrftoken
 * @param method
 * @returns {boolean}
 */
function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

jQuery.ajaxSetup({
  error: function (x, status, error) {
    if (x.status == 401 || x.status == 503) {
      location.reload();
    }
  },
  beforeSend: function (xhr, settings) {
    // Sends the csrf token for required methods
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader('X-CSRFToken', csrftoken);
    }
  }
});

window.$http = axios.create({
  validateStatus: function (status) {
    var isValidStatus = status !== 401 && status !== 503;

    if (!isValidStatus) {
      Sentry.captureException(new Error('Page has been reloaded by receiving a status code: ' + status));
      location.reload();
    }

    return isValidStatus;
  },
  xsrfCookieName: csrfTokenCookieName,
  xsrfHeaderName: 'X-CSRFToken'
});

window.$http.CancelToken = axios.CancelToken;
window.$http.isCancel = axios.isCancel;

// By default, stickit is only listening to input/changes events, but if the user is deleting a character, none of
// those events are triggered, so we should listen to the keyup event. It's a good idea as well to listen to cut and
// paste events, so we can update the models if the user cut/paste any value
Backbone.Stickit.addHandler({
  selector: 'input',
  events: ['propertychange', 'input', 'change', 'cut', 'paste', 'keyup']
});

// /**
//  * main.js
//  *
//  * @package        Ebury
//  * @subpackage     Ebury Summary Page
//  * @author         Craig Richardson
//  * @author         Email - craig.richardson@ebury.com
//  * @description    Add current page name to a the Bodys ID - Used for styling in main.less
//  */

/**
 * Destroy a Bootstrap tooltip synchronously
 *
 * https://github.com/twbs/bootstrap/issues/16376
 *
 * @param $targetElement The tooltip element
 */
function destroyTooltip($targetElement) {
  var bsData = null;

  if ($targetElement.data) {
    bsData = $targetElement.data('bs.tooltip');

    if (bsData && bsData.$tip) {
      bsData.$tip.removeClass('fade');
    }

    $targetElement.tooltip('destroy');
  }
}

/**
 * Compares if the given decimals are equal or not
 * @param decimal1 {Decimal}
 * @param decimal2 {Decimal}
 * @return {Boolean}
 */
function decimalsAreEqual(decimal1, decimal2) {
  var areEquals;
  if (decimal1 === null && decimal2 === null) {
    areEquals = true;
  } else if (decimal1 === null && decimal2 !== null) {
    areEquals = false;
  } else if (decimal1 !== null && decimal2 === null) {
    areEquals = false;
  } else if (typeof decimal1 === 'undefined' && typeof decimal2 === 'undefined') {
    areEquals = true;
  } else if (typeof decimal1 !== 'undefined' && typeof decimal2 === 'undefined') {
    areEquals = false;
  } else if (typeof decimal1 === 'undefined' && typeof decimal2 !== 'undefined') {
    areEquals = false;
  } else {
    areEquals = decimal1.eq(decimal2);
  }
  return areEquals;
}

/**
 * Coverts the given amount to another currency
 * @param amount {Decimal}
 * @param fromCurrency {String}
 * @param toCurrency {String}
 * @param rate {Decimal}
 * @param rateSymbol {String}
 * @return {Decimal}
 */
function convertAmount(amount, fromCurrency, toCurrency, rate, rateSymbol) {
  var convertedAmount = null;
  if (fromCurrency === toCurrency) {
    convertedAmount = amount;
  } else if (fromCurrency + toCurrency === rateSymbol) {
    convertedAmount = amount.times(rate);
  } else {
    convertedAmount = amount.dividedBy(rate);
  }
  return convertedAmount;
}

/**
 * Generate unique IDs for use as pseudo-private/protected names.
 * @return {string}
 */
function ID() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
}

/**
 * Capitalises the given text (test -> Test)
 * @param text
 * @return {string}
 */
function capitalise(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Format seconds for get-rate timers
 * @param {?(string|number)=} Time in seconds
 * @return {string} Number as string to 1 decimal precision
 */
function format_number_seconds(seconds) {
  var float;
  var formatted = '0.0';

  if (!seconds) return formatted;

  float = parseFloat(seconds);

  // check for NaN
  if (float !== float) return formatted;

  formatted = float.toFixed(1);

  return formatted;
}

function setUpTooltipsByJQueryWhenEllipsisIsActive(elements) {
  $.each(elements, function (idx, el) {
    if (el.offsetWidth < el.scrollWidth) {
      $(el).tooltip({
        title: $(el).text().trim(),
        container: 'body'
      });
    }
  });
}
