//
// Create .val() hooks to get and set formatted numbers in inputs using cleave.
//

// We check if any hooks already exist, and cache them in case we need to re-use them later on.
var origHookGet = null, origHookSet = null;

// Check if a text valHook already exists.
if ($.isPlainObject($.valHooks.text)) {
  // Preserve the original valhook function we'll call this for values we're not explicitly handling.
  if ($.isFunction($.valHooks.text.get)) origHookGet = $.valHooks.text.get;
  if ($.isFunction($.valHooks.text.set)) origHookSet = $.valHooks.text.set;
} else {
  // Define an object for the new valhook.
  $.valHooks.text = {};
}

/**
 * Define the valHook to return normalised field data against an input
 * which has been tagged by the number formatter.
 *
 * @param {object} el: The raw DOM element that we're getting the value from.
 *
 * @return mixed: Returns the value that was written to the element as a
 *          javascript number, or undefined to let jQuery handle it normally.
 */
$.valHooks.text.get = function (el) {
  // Get the element, and its data.
  var $this = $(el);
  var value = null;
  var cleave = $this.data('cleave');

  // Does this element have cleave?
  if (!cleave) {
    // Check if the valhook function already existed
    if ($.isFunction(origHookGet)) {
      // There was, so go ahead and call it
      value = origHookGet(el);
    } else {
      // No previous function, return undefined to have jQuery take care of retrieving the value
      value = undefined;
    }
  } else {
    // Remove formatting, and return as number.
    if (el.value === '') {
      value = '';
    } else {
      // Convert to a number.
      value = cleave.getRawValue();
    }
  }
  return value;
};

/**
 * A valhook which formats a number when run against an input
 * which has been tagged by the number formatter.
 *
 * @param {object} el: The raw DOM element (input element).
 * @param {float} val: The number to set into the value field.
 *
 * @return mixed: Returns the value that was written to the element,
 *          or undefined to let jQuery handle it normally.
 */
$.valHooks.text.set = function (el, val) {
  // Get the element, and its data.
  var $this = $(el);
  var value = null;
  var cleave = $this.data('cleave');

  // Does this element have cleave?
  if (!cleave) {
    // Check if the valhook function already exists
    if ($.isFunction(origHookSet)) {
      // There was, so go ahead and call it
      value = origHookSet(el, val);
    } else {
      // No previous function, return undefined to have jQuery take care of retrieving the value
      value = undefined;
    }
  } else {
    if (val instanceof Decimal) {
      val = val.toNumber();
    }
    cleave.setRawValue(val);
    value = cleave.getFormattedValue();
  }
  return value;
};